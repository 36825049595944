<template>
  <div class="flyCall">
    <vue-scroll :ops="ops">
      <h2 class="flyCall__title">FLYCalendar</h2>
      <div class="flyCall_wrapper">
        <div class="flyCall__left">
          <div class="type-trip">
            <button
              :class="[{ 'trip-button__active': type_trip === 'one_way' }]"
              @click="onChangeTripType('one_way')" class="trip-button button__first">
              <span class="text">
                <SvgIcon name="plane"/>
            </span>
              <span class="text">
                One-way
            </span>
            </button>
            <button
              :class="[{ 'trip-button__active': type_trip === 'round_trip' }]"
              @click="onChangeTripType('round_trip')" class="trip-button">
              <span class="text">
                <SvgIcon name="two_planes"/>
              </span>
              <span class="text">
                Round-Trip
              </span>
            </button>
          </div>
          <div class="year-trip" style="border-top-left-radius: 0; border-top-right-radius: 0">
            <div class="years-container">
              <button
                class="trip-button"
                v-for="(year, index) of optionYears"
                :key="year.year"
                :value="year"
                :class="[{ 'trip-button__active': selectedYear.year === year.year }]"
                @click="selectYear(optionYears[index])"
                      ><span class="text">{{ year.year }}</span>
              </button>
            </div>
          </div>
          <div class="date_wrapper">
            <div class="list_wrapper">
              <vue-scroll :ops="ops">
                <button
                  v-for="month of months"
                  :key="month.id"
                  class="list_item"
                  @click="setMonthsToCalendar(month)"
                  :class="[
                    { list_item__active: activeMonth.id === month.id },
                  ]"
                  :hidden="!selectedYear.availableMonths.includes(month.id)"
                >
                  <span>{{ month.label }}</span>
                </button>
              </vue-scroll>
              </div>
              <div class="picker_wrapper">
                <v-date-picker v-if="isOneDay"
                  class="picker_date"
                  color="green"
                  is-expanded
                  v-model="singleDate"
                  locale="en"
                  ref="calendar"
                  :min-date="selectedYear.min_date"
                  :max-date="selectedYear.max_date"
                  @update:from-page="changeDatePicker"
                />
                <v-date-picker v-else
                  class="picker_date"
                  color="green"
                  is-expanded
                  is-range
                  v-model="rangeDate"
                  locale="en"
                  ref="calendar"
                  :min-date="selectedYear.min_date"
                  :max-date="selectedYear.max_date"
                  @update:from-page="changeDatePicker"
                />
              </div>
          </div>
          <button
            class="trip-button saved_dates trip-button__active"
            @click="flyDatesHandler()"
          >
            Saved dates
          </button>
        </div>
        <div class="flyCall__right">
          <p v-if="isOneDay" class="chosen_var">
            {{moment(singleDate).format('dddd, MMMM DD, YYYY')}}
          </p>
          <p v-else class="chosen_var">
            {{moment(rangeDate.start).format('dddd, MMMM DD, YYYY')}} - {{moment(rangeDate.end).format('dddd, MMMM DD, YYYY')}}
          </p>
          <div class="flyCall__right_twoItems">
            <div class="airports_wrapper">
              <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif; margin-bottom: 8px;">
                Potential departure airports and locations
              </p>
              <div class="airports_line"
                v-for="(departure, index) in departures"
                :key="departure.listId"
              >
                <div style="width: 78%">
                  <AirportsInput
                    :defaultValue="departure.name"
                    @setValue="(value) => setAirport(value, departure.listId, 'departure')"
                  />
                  <span
                    v-if="!departure.name && isNotReadyToSubmit"
                    class="form__field-invalid form__field-invalid--right-side"
                  >
                  This field is required
                </span>
                </div>
                <div v-if="departures.length === index + 1 && departures.length < 3" class="button_plus_wrapper">
                  <button
                    @click="addAirportToList('departure')"
                    class="plus_button"
                  >
                    <SvgIcon name="plus4" />
                  </button>
                </div>
                <div class="button_plus_wrapper">
                  <button
                    @click="(e) => removeAirport(departure.listId, 'departure', e.currentTarget)"
                    class="plus_button del_button"
                  >
                    <SvgIcon name="plus4" />
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="input_wrapper">
              <p class="inputs_label">Departure Re-Routing<br/> Radius</p>
              <input
                class="field"
                type="number"
                v-model="departureRadius1"
                placeholder="Enter nautical miles"
                autocomplete="off"
              />
            </div> -->
          </div>
          <div class="flyCall__right_twoItems">
            <div class="airports_wrapper">
              <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif; margin-bottom: 8px;">
                Potential landing airports and locations
              </p>
              <div class="airports_line"
                v-for="(landing, index) in landings"
                :key="landing.listId"
              >
                <div style="width: 78%">
                  <AirportsInput
                    :defaultValue="landing.name"
                    @setValue="(value) => setAirport(value, landing.listId, 'landing')"
                  />
                  <span
                    v-if="!landing.name && isNotReadyToSubmit"
                    class="form__field-invalid form__field-invalid--right-side"
                  >
                  This field is required
                </span>
                </div>
                <div v-if="landings.length === index + 1 && landings.length < 3" class="button_plus_wrapper">
                  <button
                    @click="addAirportToList('landing')"
                    class="plus_button"
                  >
                    <SvgIcon name="plus4"/>
                  </button>
                </div>
                <div class="button_plus_wrapper">
                  <button
                    @click="(e) => removeAirport(landing.listId, 'landing', e.currentTarget)"
                    class="plus_button del_button"
                  >
                    <SvgIcon name="plus4" />
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="input_wrapper">
              <p class="inputs_label">Departure Re-Routing<br/> Radius</p>
              <input
                class="field"
                type="number"
                v-model="departureRadius2"
                placeholder="Enter nautical miles"
                autocomplete="off"
              />
            </div> -->
          </div>
          <p class="flyCall__right_subtitle">CHARTER TYPE (select only one option)</p>
          <div class="charter_type_wrapper">
            <button class="trip-button"
              :class="[
                { 'trip-button__active': charterType === 1 },
              ]"
              @click="setCharterType(1)"
            >
              <span class="text">Charter aircraft</span>
            </button>
            <button class="trip-button"
              :class="[
                { 'trip-button__active': charterType === 2 },
              ]"
              @click="setCharterType(2)"
            >
              <span class="text">Charter space</span>
            </button>
          </div>

          <div class="input_wrapper">
            <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">Number of spaces</p>
            <input
              style="width: 200px; margin-bottom: 15px;"
              class="field"
              type="number"
              v-model="numberOfSpaces"
              placeholder="Enter number of spaces"
              autocomplete="off"
            />
          </div>

          <div class="input_wrapper">
            <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">BUDGET GOAL</p>
            <input
              style="width: 200px; margin-bottom: 15px;"
              class="field"
              type="number"
              v-model="budgetGoal"
              placeholder="Enter $ amount"
              autocomplete="off"
            />
          </div>

          <button class="launch-btn" style="margin-top: 35px"
          @click="submitHandler()">
            <span class="text">Launch</span>
          </button>
        </div>
      </div>
    </vue-scroll>
    <!-- ----------------------------------------------------------------- -->
    <FlyDatesPopup @close="closePopup" />
    <InitiateTransactionPopUp @continue="getPayment" />
    <CompleteTransactionPopUp @success="onSuccess" @error="onError" />
    <InfoPopup/>
  </div>
</template>

<script>
  import { bookingAPI } from '@/api/booking'
  import moment from 'moment'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import AirportsInput from '../../provider/AutomatedScheduler/AirportsInput/AirportsInput.vue'
  import './FlyCall.styl'
  //
  import FlyDatesPopup from '@/components/common/modals/FlyDatesPopup'
  import InitiateTransactionPopUp from '@/components/common/modals/InitiateTransactionPopUp'
  import CompleteTransactionPopUp from '@/components/common/modals/CompleteTransactionPopUp'
  import InfoPopup from '@/components/common/modals/InfoPopup';

  export default {
    name: 'FlyCalendar',
    data: () => ({
      departures: [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ],
      landings: [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ],
      departureRadius1: 0,
      type_trip: 'one_way',
      departureRadius2: 0,
      numberOfSpaces: '',
      budgetGoal: '',
      optionYears: [],
      selectedYear: {},
      months: [
        {id: 0, label: 'January'},
        {id: 1, label: 'February'},
        {id: 2, label: 'March'},
        {id: 3, label: 'April'},
        {id: 4, label: 'May'},
        {id: 5, label: 'June'},
        {id: 6, label: 'July'},
        {id: 7, label: 'August'},
        {id: 8, label: 'September'},
        {id: 9, label: 'October'},
        {id: 10, label: 'November'},
        {id: 11, label: 'December'},
      ],
      activeMonth: {},
      dateFormat: 'MM/DD/YYYY',
      charterType: 1,
      rangeDate: {
        start: '',
        end: '',
      },
      singleDate: new Date(),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      isNotReadyToSubmit: false,
      flyCalPayload: null,
      departureIdCounter: 0,
      arrivalIdCounter: 0,
    }),
  computed: {
    calendar () {
      return this.$refs.calendar
    },
    isRangeDate () {
      return this.rangeDate !== null;
    },
    isOneDay () {
      return this.singleDate !== null;
    },
  },
  async created () {
    // prepare `optionYears` and `selectedYear` ------------------------------
    const d = new Date()
    const currentDayNum = d.getDate()
    const currentMonthNum = d.getMonth()
    const currentFullYear = d.getFullYear()

    const currentYearMonths = []
    for (let i = currentMonthNum; i <= 11; i++) {
      currentYearMonths.push(i)
    }

    const nextYearMonths = []
    for (let i = 0; i <= currentMonthNum; i++) {
      nextYearMonths.push(i)
    }

    this.optionYears = [
      {
        availableMonths: currentYearMonths,
        year: currentFullYear,
        min_date: new Date(currentFullYear, currentMonthNum, currentDayNum),
        max_date: new Date(currentFullYear + 1, 11, 31),
      },
      {
        availableMonths: nextYearMonths,
        year: currentFullYear + 1,
        min_date: new Date(currentFullYear + 1, 0, 1),
        max_date: new Date(currentFullYear + 1, currentMonthNum, currentDayNum),
      },
    ]

    this.selectedYear = {
      availableMonths: currentYearMonths,
      year: currentFullYear,
      min_date: new Date(currentFullYear, currentMonthNum, currentDayNum),
      max_date: new Date(currentFullYear + 1, 11, 31),
    }
    // -----------------------------------------------------------------------
  },
  mounted () {
    this.activeMonth = this.months[this.selectedYear.availableMonths[0]];
  },
  methods: {
    isReadyToSubmit () {
      return (this.rangeDate.start || this.singleDate) &&
        this.charterType &&
        (this.departures.length > 0 && this.departures[0].id) &&
        (this.landings.length > 0 && this.landings[0].id) &&
        /^[0-9]+$/.test(this.numberOfSpaces) &&
        /^[0-9]+$/.test(this.budgetGoal)
    },
    async submitHandler () {
      if (!this.isReadyToSubmit()) {
        console.log('Invalid fields.')
        this.isNotReadyToSubmit = true

        return false
      }

      this.isNotReadyToSubmit = false

      const flyCalPayload = {
        direction: this.isOneDay ? 2 : 1,
        bookingCategory: this.charterType === 1 ? 'aircraft' : 'spaces',
        firstDepartureId: this.departures[0].id,
        secondDepartureId: this.departures.length > 1 && this.departures[1].id
          ? this.departures[1].id
          : null,
        thirdDepartureId: this.departures.length > 2 && this.departures[2].id
          ? this.departures[2].id
          : null,
        firstArrivalId: this.landings[0].id,
        secondArrivalId: this.landings.length > 1 && this.landings[1].id
          ? this.landings[1].id
          : null,
        thirdArrivalId: this.landings.length > 1 && this.landings[1].id
          ? this.landings[1].id
          : null,
        departureDate: this.isOneDay ? this.singleDate.toLocaleDateString('en-US') : this.rangeDate.start.toLocaleDateString('en-US'),
        returnDate: this.isOneDay ? null : this.rangeDate.end.toLocaleDateString('en-US'),
        passengersNumber: Number(this.numberOfSpaces),
        budgetGoal: Number(this.budgetGoal),
        // departureReroutingRadius: Number(this.departureRadius1),
        // arrivalReroutingRadius: Number(this.departureRadius2),
        isMobileRequest: false,
      }

      // console.log(flyCalPayload)

      const flyCalStatus = await this.checkFlyCalStatus();

      // console.log(flyCalStatus)

      if (flyCalStatus) {
        try {
          await bookingAPI.flycalCreate(flyCalPayload);
          const message = {
            title: 'Thank you!',
            text: 'Request created.',
          }
          this.$modal.show('ThanksPopup', {message});
          this.clearForm();
        } catch (e) {
          console.log(e);
        }
      } else {
        this.flyCalPayload = flyCalPayload
        await bookingAPI.getFlycalPaymentInfo().then((res) => {
          this.price = res.data;
        });
        this.$modal.show('initiate', { transactionType: 'flycal', price: this.price })
      }
    },
    clearForm () {
      this.departures = [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ];
      this.landings = [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ];
      // this.departureRadius1= 0;
      // departureRadius2= 0;
      this.type_trip = 'one_way';
      this.numberOfSpaces = '';
      this.budgetGoal = '';
    },
    closePopup () {
      this.$modal.hide('flycal-requests')
    },
    getPayment () {
      this.$modal.hide('initiate');
      this.$modal.show('complete', { transactionType: 'flycal', price: this.price });
    },
    onError (error) {
        this.$modal.hide('complete')
        this.$modal.show('InfoPopup', {
          title: 'Error',
          text: error.message,
        });
        console.log('error')
      },
    async onSuccess () {
      console.log('success');
      this.$modal.hide('complete');
      await bookingAPI.flycalCreate(this.flyCalPayload);
      const message = {
        title: 'Thank you!',
        text: 'Your FLYCalendar membership has been initiated.',
      }
      this.$modal.show('ThanksPopup', {message});
    },
    // -----------------------------------------------------------------------
    moment,
    setCharterType (type) {
      this.charterType = type;
    },
    async setMonthsToCalendar (month) {
      this.activeMonth = month;
      await this.calendar.move({ month: month.id + 1, year: this.selectedYear.year });
    },
    setAirport (value, id, type) {
      if (type === 'departure') {
        this.departures = this.departures.map((departure) => {
          if (departure.listId === id) {
            return ({
              id: value.id,
              name: value.name,
              listId: id,
            })
          } else return departure;
        });
      } else {
        this.landings = this.landings.map((landing) => {
          if (landing.listId === id) {
            return ({
              id: value.id,
              name: value.name,
              listId: id,
            })
          } else return landing;
        })
      }
    },
    addAirportToList (type) {
      if (type === 'departure') {
        this.departureIdCounter = this.departureIdCounter + 1;
        this.departures = [...this.departures, { name: '', id: null, listId: this.departureIdCounter }]
      } else {
        this.arrivalIdCounter = this.arrivalIdCounter + 1;
        this.landings = [...this.landings, { name: '', id: null, listId: this.arrivalIdCounter }]
      }
    },
    onChangeTripType (tripType) {
      this.type_trip = tripType;
      if (this.type_trip === 'one_way') {
        if (this.rangeDate.start) {
          this.singleDate = this.rangeDate.start;
        } else {
          this.singleDate = new Date();
        }
      } else {
        this.singleDate = null;
      }
      this.rangeDate = {
          start: '',
          end: '',
      };
    },
    async removeAirport (id, type, e) {
      if (type === 'departure') {
        if (this.departures.filter(({listId}) => listId === id)[0].name.length > 0) {
          this.setAirport({listId: id, id: null, name: ''}, id, 'departure')
          e.closest('.airports_line').querySelector('input').value = ''
          return
        }
        if (this.departures.filter(({listId}) => listId === id)[0].name.length === 0 && this.departures.length > 1) {
          this.departures = this.departures.filter(({listId}) => listId !== id);
        }
      } else {
        if (this.landings.filter(({listId}) => listId === id)[0].name.length > 0) {
          this.setAirport({listId: id, id: null, name: ''}, id, 'landing')
          e.closest('.airports_line').querySelector('input').value = ''
          return
        }
        if (this.landings.filter(({listId}) => listId === id)[0].name.length === 0 && this.landings.length > 1) {
          this.landings = this.landings.filter(({listId}) => listId !== id);
        }
      }
    },
    async selectYear (value) {
      const yearObj = this.optionYears.filter(y => y.year === value.year)[0];
      this.activeMonth = this.months[yearObj.availableMonths[0]];
      this.selectedYear = yearObj;

      await setTimeout(() => {
        this.$refs.calendar.move(yearObj.min_date)
      }, 0);
    },
    async flyDatesHandler () {
      const response = await this.$store.dispatch('showFlyCals');

      if (response.data?.length > 0) {
        const listItems = [];

        response.data.forEach((item, index) => {
          const departureStrings = []
          const arrivalStrings = []
          const departureStringsSmall = []
          const arrivalStringsSmall = []

          for (let i = 0; i < 3; i++) {
            if (i === 0) {
              departureStrings.push(`${item.firstDepartureName} (${item.firstDepartureAirportCode})`)
              arrivalStrings.push(`${item.firstArrivalName} (${item.firstArrivalAirportCode})`)
              departureStringsSmall.push(`OUT: ${item.firstDepartureAirportCode}`)
              arrivalStringsSmall.push(`IN: ${item.firstArrivalAirportCode}`)
            } else if (i === 1) {
              if (item.secondDepartureName) {
                departureStrings.push(`${item.secondDepartureName} (${item.secondDepartureAirportCode})`)
                departureStringsSmall.push(`OUT: ${item.secondDepartureAirportCode}`)
              } else if (item.secondArrivalName) {
                arrivalStrings.push(`${item.secondArrivalName} (${item.secondArrivalAirportCode})`)
                arrivalStringsSmall.push(`IN: ${item.secondArrivalAirportCode}`)
              }
            } else {
              if (item.thirdDepartureName) {
                departureStrings.push(`${item.thirdDepartureName} (${item.thirdDepartureAirportCode})`)
                departureStringsSmall.push(`OUT: ${item.thirdDepartureAirportCode}`)
              } else if (item.thirdArrivalName) {
                arrivalStrings.push(`${item.thirdArrivalName} (${item.thirdArrivalAirportCode})`)
                arrivalStringsSmall.push(`IN: ${item.thirdArrivalAirportCode}`)
              }
            }
          }

          listItems.push({
            createdOn: item.createdOn,
            tripDateText: item.direction === 1
              ? `${moment(item.departureDate).format('M/D/YY')} - ${moment(item.returnDate).format('M/D/YY')}`
              : `${moment(item.departureDate).format('M/D/YY')}`,
            directionText: item.direction === 1 ? 'RT' : 'OW',
            bookingType: item.bookingCategory === 'aircraft' ? 'Aircraft' : 'Spaces',
            passengersNumber: item.passengersNumber,
            locationsText: `${departureStrings.join(', ')} - ${arrivalStrings.join(', ')}`,
            locationsTextMobile: `${departureStringsSmall.join(', ')} - ${arrivalStringsSmall.join(', ')}`,
            // departureReroute: item.departureReroutingRadius,
            // arrivalReroute: item.arrivalReroutingRadius,
            budgetGoal: item.budgetGoal,
            paid: item.paid,
          })
        })

        this.$modal.show('flycal-requests', listItems)
      } else {
        const message = {
          title: 'No data.',
        };
        this.$modal.show('ThanksPopup', {message});
      }
    },
    async checkFlyCalStatus () {
      const flyCalStatus = await this.$store.dispatch('checkFlyCalStatus');
      return flyCalStatus;
    },
    changeDatePicker (value) {
        if (this.selectedYear.year !== value.year) {
          this.selectYear(value);
        }
    },
  },
    watch: {
      singleDate () {
        if (this.singleDate === null && this.type_trip === 'one_way') {
          this.onChangeTripType('one_way')
        }
      },
    },
  components: {
    SvgIcon,
    AirportsInput,
    FlyDatesPopup,
    InitiateTransactionPopUp,
    CompleteTransactionPopUp,
    InfoPopup,
  },
}
</script>
